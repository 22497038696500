@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?kpy50');
  src:  url('fonts/icomoon.eot?kpy50#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?kpy50') format('truetype'),
    url('fonts/icomoon.woff?kpy50') format('woff'),
    url('fonts/icomoon.svg?kpy50#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-audio-file:before {
  content: "\e980";
}
.icon-doc-file:before {
  content: "\e981";
}
.icon-opendrive-file:before {
  content: "\e982";
}
.icon-pdf-file:before {
  content: "\e983";
}
.icon-ppt-file:before {
  content: "\e984";
}
.icon-video-file:before {
  content: "\e985";
}
.icon-word-file:before {
  content: "\e986";
}
.icon-xls-file:before {
  content: "\e987";
}
.icon-landing-apps:before {
  content: "\e97f";
}
.icon-landing-api-settings:before {
  content: "\e975";
}
.icon-landing-branding:before {
  content: "\e976";
}
.icon-landing-content:before {
  content: "\e977";
}
.icon-landing-data:before {
  content: "\e978";
}
.icon-landing-navigation:before {
  content: "\e979";
}
.icon-landing-organisation:before {
  content: "\e97a";
}
.icon-landing-people:before {
  content: "\e97b";
}
.icon-landing-search:before {
  content: "\e97c";
}
.icon-landing-security:before {
  content: "\e97d";
}
.icon-landing-social:before {
  content: "\e97e";
}
.icon-blogs:before {
  content: "\e96f";
}
.icon-groups:before {
  content: "\e970";
}
.icon-message-wall:before {
  content: "\e971";
}
.icon-queries:before {
  content: "\e972";
}
.icon-questions:before {
  content: "\e973";
}
.icon-ideas:before {
  content: "\e974";
}
.icon-feeds:before {
  content: "\e96e";
}
.icon-settings_outline:before {
  content: "\e96d";
}
.icon-save_alt:before {
  content: "\e96c";
}
.icon-forum:before {
  content: "\e966";
}
.icon-question_answer:before {
  content: "\e966";
}
.icon-chat_bubble:before {
  content: "\e967";
}
.icon-chat_bubble_outline:before {
  content: "\e968";
}
.icon-add:before {
  content: "\e900";
}
.icon-clear:before {
  content: "\e901";
}
.icon-close:before {
  content: "\e901";
}
.icon-publish:before {
  content: "\e969";
}
.icon-vertical_align_bottom:before {
  content: "\e962";
}
.icon-file_upload:before {
  content: "\e96a";
}
.icon-keyboard_arrow_down:before {
  content: "\e902";
}
.icon-keyboard_arrow_left:before {
  content: "\e903";
}
.icon-keyboard_arrow_right:before {
  content: "\e904";
}
.icon-keyboard_arrow_up:before {
  content: "\e905";
}
.icon-keyboard_backspace:before {
  content: "\e906";
}
.icon-crop:before {
  content: "\e96b";
}
.icon-dehaze:before {
  content: "\e907";
}
.icon-navigate_before:before {
  content: "\e908";
}
.icon-chevron_left:before {
  content: "\e908";
}
.icon-navigate_next:before {
  content: "\e909";
}
.icon-chevron_right:before {
  content: "\e909";
}
.icon-remove_red_eye:before {
  content: "\e90a";
}
.icon-visibility:before {
  content: "\e90a";
}
.icon-view_comfortable:before {
  content: "\e90b";
}
.icon-apps:before {
  content: "\e90c";
}
.icon-arrow_back:before {
  content: "\e90d";
}
.icon-arrow_drop_down:before {
  content: "\e90e";
}
.icon-arrow_drop_up:before {
  content: "\e90f";
}
.icon-arrow_forward:before {
  content: "\e910";
}
.icon-check:before {
  content: "\e911";
}
.icon-expand_less:before {
  content: "\e912";
}
.icon-expand_more:before {
  content: "\e913";
}
.icon-menu:before {
  content: "\e914";
}
.icon-keyboard_control:before {
  content: "\e915";
}
.icon-more_vert:before {
  content: "\e916";
}
.icon-arrow_upward:before {
  content: "\e95f";
}
.icon-arrow_downward:before {
  content: "\e960";
}
.icon-arrow_back_ios:before {
  content: "\e917";
}
.icon-arrow_forward_ios:before {
  content: "\e918";
}
.icon-do_not_disturb_alt:before {
  content: "\e919";
}
.icon-show_chart:before {
  content: "\e91a";
}
.icon-notifications:before {
  content: "\e91b";
}
.icon-notifications_none:before {
  content: "\e91c";
}
.icon-notifications_off:before {
  content: "\e91d";
}
.icon-notifications_active:before {
  content: "\e91e";
}
.icon-people_outline:before {
  content: "\e963";
}
.icon-person:before {
  content: "\e964";
}
.icon-person_outline:before {
  content: "\e95d";
}
.icon-perm_identity:before {
  content: "\e95d";
}
.icon-thumb_down_alt:before {
  content: "\e91f";
}
.icon-thumb_up_alt:before {
  content: "\e920";
}
.icon-check_box:before {
  content: "\e921";
}
.icon-check_box_outline_blank:before {
  content: "\e922";
}
.icon-radio_button_checked:before {
  content: "\e923";
}
.icon-star:before {
  content: "\e924";
}
.icon-grade:before {
  content: "\e924";
}
.icon-star_half:before {
  content: "\e925";
}
.icon-star_outline:before {
  content: "\e926";
}
.icon-account_box:before {
  content: "\e927";
}
.icon-account_circle:before {
  content: "\e928";
}
.icon-delete:before {
  content: "\e929";
}
.icon-description:before {
  content: "\e92a";
}
.icon-dns:before {
  content: "\e92b";
}
.icon-done:before {
  content: "\e92c";
}
.icon-done_all:before {
  content: "\e92d";
}
.icon-exit_to_app:before {
  content: "\e92e";
}
.icon-favorite:before {
  content: "\e92f";
}
.icon-favorite_outline:before {
  content: "\e930";
}
.icon-highlight_remove:before {
  content: "\e931";
}
.icon-history:before {
  content: "\e932";
}
.icon-restore:before {
  content: "\e932";
}
.icon-home:before {
  content: "\e933";
}
.icon-hourglass_empty:before {
  content: "\e934";
}
.icon-hourglass_full:before {
  content: "\e935";
}
.icon-label:before {
  content: "\e936";
}
.icon-label_outline:before {
  content: "\e937";
}
.icon-language:before {
  content: "\e938";
}
.icon-launch:before {
  content: "\e939";
}
.icon-open_in_new:before {
  content: "\e939";
}
.icon-list:before {
  content: "\e93a";
}
.icon-lock_open:before {
  content: "\e93b";
}
.icon-lock_outline:before {
  content: "\e93c";
}
.icon-loyalty:before {
  content: "\e93d";
}
.icon-search:before {
  content: "\e93e";
}
.icon-settings:before {
  content: "\e93f";
}
.icon-settings_applications:before {
  content: "\e940";
}
.icon-subject:before {
  content: "\e95e";
}
.icon-swap_horiz:before {
  content: "\e941";
}
.icon-swap_vert:before {
  content: "\e942";
}
.icon-thumb_down:before {
  content: "\e943";
}
.icon-thumb_up:before {
  content: "\e944";
}
.icon-thumbs_up_down:before {
  content: "\e945";
}
.icon-toc:before {
  content: "\e946";
}
.icon-today:before {
  content: "\e947";
}
.icon-trending_down:before {
  content: "\e965";
}
.icon-trending_neutral:before {
  content: "\e948";
}
.icon-trending_up:before {
  content: "\e949";
}
.icon-verified_user:before {
  content: "\e94a";
}
.icon-view_agenda:before {
  content: "\e94b";
}
.icon-view_carousel:before {
  content: "\e94c";
}
.icon-view_day:before {
  content: "\e94d";
}
.icon-view_headline:before {
  content: "\e94e";
}
.icon-view_list:before {
  content: "\e94f";
}
.icon-view_module:before {
  content: "\e950";
}
.icon-visibility_off:before {
  content: "\e951";
}
.icon-reorder:before {
  content: "\e952";
}
.icon-zoom_in:before {
  content: "\e953";
}
.icon-indeterminate_check_box:before {
  content: "\e961";
}
.icon-date_range:before {
  content: "\e954";
}
.icon-delete_forever:before {
  content: "\e955";
}
.icon-delete_outline:before {
  content: "\e956";
}
.icon-arrow_right_alt:before {
  content: "\e957";
}
.icon-drag_indicator:before {
  content: "\e958";
}
.icon-thumb_down_off_alt:before {
  content: "\e959";
}
.icon-thumb_up_off_alt:before {
  content: "\e95a";
}
.icon-toggle_off:before {
  content: "\e95b";
}
.icon-toggle_on:before {
  content: "\e95c";
}
